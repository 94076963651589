<template>
  <div :class="[$style.block, { [$style.subMenu]: innerBlock }]">
    <div :class="[$style.header, { [$style.subMenu]: innerBlock }]">
      <h3 v-if="innerBlock" :class="$style.name">
        {{ title }}
      </h3>
      <h2 v-else :class="$style.name">
        {{ title }}
      </h2>
      <Icon
        name="arrow"
        @click="$emit('handle-show')"
        :class="[$style.icon, $style.arrow, { [$style.active]: isOpen }]"
      />
    </div>
    <div v-show="isOpen" :class="$style.content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
export default {
  components: { Icon },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    innerBlock: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.block {
  padding: 0.5rem 0;
  margin-bottom: 2rem;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    &.subMenu {
      justify-content: left;
    }
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.5rem;
      cursor: pointer;
      &.arrow {
        margin-right: 1rem;
        transition: transform 0.3s ease;
        transform: rotate(-180deg);
        &.active {
          transition: transform 0.3s ease;
          transform: rotate(-90deg);
        }
      }
    }
  }
  .content {
    padding-top: 0.5rem;
  }
  border-bottom: 0.1rem dashed $gray;

  &.subMenu {
    border-bottom: none;
  }
}
</style>
